import { GlobalShoeBoxProps } from "components/dist/organisms/GlobalShoeBox";
import { FileUploading } from "src/types/formelement";

export const SET_REJECTED_FILES = 'SET_REJECTED_FILES';
export const SET_UPLOADING_STATE = 'SET_UPLOADING_STATE';
export const SET_USERS_LOANS_MAP = 'SET_USERS_LOANS_MAP';
export const SET_USERS_AVATAR_MAP = 'SET_USERS_AVATAR_MAP';
export const SET_MOVE_SHOEBOX_FILES_TO_LOAN = 'SET_MOVE_SHOEBOX_FILES_TO_LOAN';
interface MoveShoeboxFilesToLoan {
    loan: GlobalShoeBoxProps['loans'][0],
    user: GlobalShoeBoxProps['loans'][0]['users'][0],
    files: GlobalShoeBoxProps['folders'][0]['files']
}

export type DashboardSidebarReducerState = {
    rejectedFiles: File[];
    uploadingState: Record<string, FileUploading>;
    usersLoansMap: Record<string, GlobalShoeBoxProps['folders'][0]['loans']>;
    usersAvatarMap: Record<string, string>;
    moveShoeboxFilesToLoan: MoveShoeboxFilesToLoan | null;
};

export const initialState: DashboardSidebarReducerState = {
    rejectedFiles: [],
    uploadingState: {},
    usersLoansMap: {},
    usersAvatarMap: {},
    moveShoeboxFilesToLoan: null
};

export type Action =
    | { type: typeof SET_REJECTED_FILES; payload: File[] }
    | { type: typeof SET_UPLOADING_STATE; payload: Record<string, FileUploading> }
    | { type: typeof SET_USERS_LOANS_MAP; payload: Record<string, GlobalShoeBoxProps['folders'][0]['loans']> }
    | { type: typeof SET_USERS_AVATAR_MAP; payload: Record<string, string> }
    | { type: typeof SET_MOVE_SHOEBOX_FILES_TO_LOAN; payload: MoveShoeboxFilesToLoan };

export const reducer = (state: DashboardSidebarReducerState, action: Action): DashboardSidebarReducerState => {
    switch (action.type) {
        case SET_REJECTED_FILES:
            return { ...state, rejectedFiles: action.payload };
        case SET_UPLOADING_STATE:
            return { ...state, uploadingState: action.payload };
        case SET_USERS_LOANS_MAP:
            return { ...state, usersLoansMap: action.payload };
        case SET_USERS_AVATAR_MAP:
            return { ...state, usersAvatarMap: action.payload };
        case SET_MOVE_SHOEBOX_FILES_TO_LOAN:
            return { ...state, moveShoeboxFilesToLoan: action.payload };
        default:
            return state;
    }
};
